.headerContainer{
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top:0;
    width: 100%;
}
.ButtonContainer{
    display: flex;
    align-items: center;
    justify-content:flex-end;
    padding:12px 20px 12px 12px;
}
.ButtonContainer > button{
    background-color: white;
    color:black;
    font-size: 13px;
    min-width: 138px;
    height:48px;
    border-radius: 30px;
    font-weight: 600;
    border: 0;
}

