.mainContainer {
    background: linear-gradient(to bottom, #1e2124, #292c31);
    min-height: 100vh;
    padding-bottom: 100px;
}

.containerNew {
    /* max-width: 1240px; */
    margin: 0 auto;
    gap:20px;
}

.spacingTop{
    padding-top: 6rem;
    margin-bottom: 30px;
}
.upperSection {
    background-color: #242424;
}

.vinlimg {
    height: 292px;
}

.vinylTitle {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 0;
    color: #ffffff;
}

.vinylratingContainer {
    /* background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%); */
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
}

.rating {
    /* width: 30px; */
    height: 12px;
}

.containerRating,
.vinylPercent {
    width: fit-content;
    background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
    padding: 10px 15px;
    border-radius: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
}

.containerRating>span {
    margin-left: 10px;
    /* font-size: 15px; */
}

.SingleChar {
    /* background-color: gray;
    border-radius: 100px;
    padding: 2px; */
}

.imgSingle {
    width: 18px;
}

.dropdownContainer {
    background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
    width: fit-content;
    padding: 5px 8px;
    border-radius: 8px;
}

.Vg {
    color: white;
    font-size: 12px;
    margin-left: 7px;
    margin-right: 7px;
}

.nameProfile {
    display: flex;
    align-items: center;
}

.firstCharacter {
    background-color: #42e3cc;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    margin-right: 10px;
}

.nameProfile {
    font-size: 13px;
    font-weight: 500;
    margin-top: 20px;
}
.claimBtn{
    background-color: #42e3cc;
    color:black;
    font-weight: 500;
    margin-right: 12px;
}
.claimContainer > button{
    min-width: 162px;
    font-size:12px;
    height: 40px;
    border-radius: 50px;
    border: 0;
    margin-bottom: 30px;
}
.wishlist{
    background-color: transparent;
    border: 1px solid gray !important;
}
.heartIcons{
    width: 11px;
    margin-right: 6px;
}
.btnSection{
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content:space-between;
}
.description{
    font-size: 12px;
    font-weight: 400;
    color:#181e23;
    margin-bottom: 0;
}

.artistInfo{
    display: flex;
    align-items:center;
    justify-content:space-between;
    margin-bottom: 4px;
}

.keyLabel{
    font-size: 12px;
    color:white;
    line-height: 20px;
    font-weight: 600;
}
.valueLabel{
    color:gray;
    font-size: 12px;
    line-height: 20px;
}
.imgTitle{
    color:white;
    font-weight: 600;
}
.galleryImg{
    width: 115px;
    border-radius: 7px;
}
.accordionBG{
    background-color:transparent;
}
.accordionBG .card-header{
    background-color:transparent !important;
}
.accordionFontSize{
    font-size: 14px;
}
.contImg{ display: flex; align-Items: flex-end }
.rightAcordContainer{
    padding-Left: 38px;
}
.tabMenu{
    display: flex; align-Items: center; background:rgba(255, 255, 255, 0.05);
}
.tabBtn{
    /* background: rgba(255, 255, 255, 0.1); */
    background: rgba(255, 255, 255, 0.1) !important;
}

.timeContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 54px;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
}
.timeContainer > p{
    color:white;
    margin-bottom: 0;
    font-size: 11px;
}

@media (min-width: 1240px){
    .containerNew{
        max-width: 1240px;
    }
}
@media (max-width:600px) {
    .contImg{
        flex-direction: column;
        align-items: flex-start;
    }
    .contImg{
        padding: 2px 20px;
    }
    /* .mainContainer{
        padding-left: 25px;
        padding-right: 25px;
    } */
}
@media (max-width:1300px){
    .mainContainer {
            padding-left: 25px;
            padding-right: 25px;
        }
}
@media (max-width:991px) {
    .rightAcordContainer{
        padding-Left:12px;
        margin-top:30px;
    }
}